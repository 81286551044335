<template>

</template>
<script>
    import {request} from "../app/api/Client";

    export default {
        methods : {
            async assessmentLogin() {
                let assessmentId = this.$route.params.assessmentId;
                try {
                    let {data: {data, message}} = await request.post(`/candidate/assessment/${assessmentId}`);
                    window.location =data.url;
                    // this.$toast.success(message);
                } catch (err) {
                    this.$toast.error('Assessment expired.');
                    this.closeWindow();
                }

            },
            closeWindow() {
                setTimeout(function () {
                    window.top.close()
                }, 2000);
            }
        },
        mounted() {
            this.assessmentLogin();
        }
    }
</script>
